import React from 'react'
import './css/noWebViewMoment.css'


export default class NoWebViewSupport extends React.Component {
  constructor() {
    super()
    this.handleMessage = this.handleMessage.bind(this)
  }

  componentDidMount() {
    // connect to websocket
    this.getEventId(this.props.code)
      .then(eventId => {
        let host = this.getHost()
        let url = `wss://${host}/api/events/${eventId}/ws`
        this.ws = new WebSocket(url)
        this.ws.onopen = () => { console.log(`connected to ${url}`) }
        this.ws.onmessage = this.handleMessage
      })
  }

  handleMessage(event) {
    console.log(`got message: ${event.data}`)
    let msg = JSON.parse(event.data)
    if (msg.type === 'start') {
      window.location.replace(`/?code=${this.props.code}`)
    }
  }

  componentWillUnmount() {
    // close websocket
    this.ws.close()
    this.ws = null
  }

  render() {
    return (<div id="container">
              <div id="msgBox">
                <div id="circle">
                  <img id="thinking" src={require('./images/thinking.png')} alt="hmm"/>
                </div>
                The current active moment is not<br/>
                enabled for web browser access.
              </div>
              <div id="msgBox2">
                <div id="firstRow">
                  <img id="logo" src={require('./images/stagecast.png')} alt="logo"/>
                    If you want to join this Moment<br/>
                    download the Stagecast App or<br/>
                    wait here until the next Moment<br/>
                    is launched.
                </div>
                <div id="footer">
                  <a href="https://apps.apple.com/us/app/stagecast/id1123631455">
                    <img id="appstore" src={require('./images/appstore.png')} alt="appstore"/>
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=se.stagecast.stagecast">
                    <img id="googleplay" src={require('./images/googleplay.png')} alt="google play"/>
                  </a>
                </div>
              </div>
            </div>)
  }

  getHost() {
    return this.props.isStaging ? 'console.staging.stagecast.io' : 'console.stagecast.io'
  }

  getEventId(code) {
    let host = this.getHost()
    return fetch(`https://${host}/api/events/codes/${code}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response
        }
        return response.json()
      })
      .then((responseBodyJson) => {
        return responseBodyJson._id
      })
  }
}
