exports.isStaging = function isStaging() {
    return window.location.hostname.includes('staging')
}

function persistUid(uidKey, uid) {
  sessionStorage.setItem(uidKey, uid)
  localStorage.setItem(uidKey, uid)
  setCookie(uidKey, uid, 10)
}

function getUid(uidKey) {
  let uid = getCookie(uidKey)
  if (uid.length === 0) {
    if (localStorage.getItem(uidKey)) {
      uid = localStorage.getItem(uidKey)
    } else if (sessionStorage.getItem(uidKey)) {
      uid = sessionStorage.getItem(uidKey)
    } else {
      uid = randomString(36)
    }
  }
  return uid
}

function makeid(length) {
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

exports.redirectTo = function redirectTo(code, isStaging, shouldLog) {
  let uidKey = 'uid'
  if (isStaging) {
    uidKey = 'staging_uid'
  }

  let uid = getUid(uidKey)
  persistUid(uidKey, uid)
  let host = isStaging ? 'console.staging.stagecast.io' : 'console.stagecast.io'
  let noise = makeid(15)
  let params = window.location.search.length == 0 ? `?code=${code}&deviceId=${uid}&logs=${shouldLog}&t=${noise}` :
      `${window.location.search}&deviceId=${uid}&t=${noise}`
  return `https://${host}/api/open${params}`
}

//function redirect(code, uid) {
//  window.location.replace(`/api/web/code/${code}/${uid}`)
//}

function setCookie(cname, cvalue, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + (exdays*24*60*60*1000))
  document.cookie = `${cname}=${cvalue};expires=${d.toUTCString()};path=/`
}

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function randomString(size) {
  let now = new Date().getTime()
  let array = []
  for (var i = 0; i < size * 1.5; i += 1) {
    array[i] = (Math.random() * now) & 255
  }

  var rawString = String.fromCharCode.apply(null, array)
  var base64String = btoa(rawString)

  return base64String.replace(/[+/=]/g, '').slice(0, size)
}
