import React from 'react'
import Join from './join'
import Lobby from './lobby'
import './css/App.css'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Switch>
            <Route path="/lobby.html" component={Lobby}/>
            <Route path="/" component={Join}/>
          </Switch>
        </Router>
      </header>
    </div>
  );
}


export default App
