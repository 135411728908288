import React from 'react'
import './css/noActiveMoment.css'

export default class NoActiveMoment extends React.Component {
  constructor() {
    super()
    this.handleMessage = this.handleMessage.bind(this)
  }

  componentDidMount() {
    // connect to websocket
    this.getRoomId(this.props.code)
      .then(roomId => {
        let host = this.getHost()
        let url = `wss://${host}/api/rooms/${roomId}/ws`
        this.ws = new WebSocket(url)
        this.ws.onopen = () => { console.log(`connected to ${url}`) }
        this.ws.onmessage = this.handleMessage
      })
  }

  handleMessage(event) {
    let msg = JSON.parse(event.data)
    if (msg.type === 'start') {
      let joinHost = this.props.isStaging ? 'join.staging.stagecast.io' : 'join.stagecast.io'
      window.location.replace(`https://${joinHost}/?code=${this.props.code}`)
    }
  }

  componentWillUnmount() {
    // close websocket
    this.ws.close()
    this.ws = null
  }

  render() {
    return (<div id="card">
              <div id="title">
                NO ACTIVE<br/>MOMENTS
              </div>
              <div id="msg">
                Stay tuned!<br/>
                Moments are<br/>
                launched any time.
              </div>
            </div>)
  }

  getHost() {
    return this.props.isStaging ? 'console.staging.stagecast.io' : 'console.stagecast.io'
  }

  getRoomId(code) {
    let host = this.getHost()
    return fetch(`https://${host}/api/rooms/codes/${code}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw response
        }
        return response.json()
      })
      .then((responseBodyJson) => {
        return responseBodyJson._id
      })
  }
}
