import React from 'react'
import './css/join.css'
import { redirectTo } from './utils'


export default class NoSuchEvent extends React.Component {
  constructor() {
    super()
    this.state = {
      value: 'Event Code',
      code: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.clearInput = this.clearInput.bind(this)
    this.doIt = this.doIt.bind(this)
    this.keyUp = this.keyUp.bind(this)
  }

  handleChange(event) {
    let value = event.target.value
    if (event.target.value > event.target.maxLength) {
      value = value.slice(0, event.target.maxLength)
    }
    this.setState({value: value})
  }

  clearInput() {
    this.setState({value: ''})
  }

  keyUp(event) {
    if (event.keyCode === 13) {
      this.doIt()
    }
  }

  doIt() {
    let code = this.state.value
    redirectTo(code, this.props.isStaging)
  }

  render() {
    if (this.state.code !== '') {
      return null
    }

    return (<div id="content">
              <div id="firstRow">
                <input value={this.state.value}
                       maxlength = "4"
                       type="number"
                       onChange={this.handleChange}
                       onKeyUp={this.keyUp}
                       onFocus={this.clearInput} />
                <input type="submit" onClick={this.doIt} value={'Join'}/>
              </div>
              <div id="secondRow">
                You typed in the wrong code. Try again!
              </div>
            </div>)
  }
}
