import React from 'react'
import './css/noWebViewMoment.css'

export default class AuthProblem extends React.Component {
  render() {
    return (<div id="container">
              <div id="msgBox">
                <div id="circle">
                  <img id="thinking" src={require('./images/thinking.png')} alt="hmm"/>
                </div>
                Authentication problem.<br/>
                Something is strange.
              </div>
            </div>)
  }
}
