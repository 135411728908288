import React from 'react'
import './css/join.css'
import queryString from 'query-string'
import { redirectTo, isStaging } from './utils'

export default class Join extends React.Component {
  constructor() {
    super()
    this.state = {
      value: 'Event Code',
      code: '',
      iframePath: '',
      showInput: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.clearInput = this.clearInput.bind(this)
    this.doIt = this.doIt.bind(this)
    this.keyUp = this.keyUp.bind(this)
  }

  componentDidMount() {
    this.queryParams = queryString.parse(this.props.location.search)
    if (this.queryParams.code) {
      this.setState({code: this.queryParams.code})
      this.doIt()
    } else {
      this.setState({showInput: true})
    }
  }

  handleChange(event) {
    let value = event.target.value
    if (event.target.value > event.target.maxLength) {
      value = value.slice(0, event.target.maxLength)
    }
    this.setState({value: value})
  }

  clearInput() {
    this.setState({value: ''})
  }

  keyUp(event) {
    if (event.keyCode === 13) {
      this.doIt()
    }
  }

  inIframe () {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  doIt() {
    let code = this.queryParams.code || this.state.value
    let iframePath = redirectTo(code, isStaging(), this.queryParams.logs === 'true' ? true : false)
    if (!this.inIframe()) {
      this.setState({iframePath: iframePath})
    } else {
      window.location.replace(iframePath)
    }
  }

  render() {
    if (this.state.iframePath !== '') {
      return (<iframe title="inner"
                      sandbox="allow-downloads allow-forms allow-modals allow-pointer-lock allow-popups allow-presentation allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
                      allow="accelerometer; camera; encrypted-media; geolocation; gyroscope; microphone; midi; payment;"
                      id="frame" src={this.state.iframePath}></iframe>)
    } else if (this.state.showInput) {
      return (<div id="content">
                <h3>Joining an activation?</h3>
                <div id="firstRow">
                  <input value={this.state.value}
                         type="number"
                         maxlength = "4"
                         onChange={this.handleChange}
                         onKeyUp={this.keyUp}
                         onFocus={this.clearInput} />
                  <input type="submit" onClick={this.doIt} value={'Join'}/>
                </div>
                <div id="secondJoinRow">
                  Get the room code from your host to join the activation.<br/>
                  <p>
                    Do you want to engage your audience and host your own virtual- or hybrid interactive events using Stagecast?
                    <br/>
                    Then head over and create a free account at <a href="https://stagecast.io">stagecast.io</a>
                  </p>
                </div>
              </div>)
    }
    return (<div id="content"></div>)
  }
}
